.cus-searchbar {
    --border-radius: 8px;
    --box-shadow: none;
    padding: 0;
    --icon-color: rgba(var(--ion-color-medium-rgb), 0.5);
    --background:  rgba(var(--ion-color-medium-rgb), 0.1);
    width: 100%;
    max-width: 260px;
    text-align: left;
  }
  @media screen and (max-width: 991px) {
    .cus-searchbar{
      max-width: calc(100% - 32px);
      margin: 0 auto;
    }
  }