

// Fonts
$font-family: "Source Sans Pro", sans-serif;
$font-inter: 'Inter', sans-serif;

// Box Modal
$border-radius: 8px;
$box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;

//Alignment
