.custom-accordion{
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.5);
    border-radius: 8px;
    overflow: hidden;
    .header-row{
        display: flex;
        flex-wrap: wrap;
        font-family: "Inter", sans-serif;
        justify-content: space-between;
        width: 100%;
        .item{
            color: var(--ion-color-dark);
            font-size: 1rem;
            min-width: 26%;
            max-width: 33.33%;
            margin: 0;
            label {
                opacity: 0.6;
                font-weight: 500;
                min-width: 90px;
                margin-right: 0.8rem;
                display: block;
            }
            .desc {
                font-weight: 600;
                font-size: 1.3em;
            }
        }
    }
    .content-block{
        .inp-row{
            display: flex;

            .input-fld{
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
                flex: 1;
                /* Firefox */
                -moz-appearance: textfield;

                background: rgba(var(--ion-color-medium-rgb), 0.1);
                border: 0;
                border-radius: 8px;
                margin-right: 15px;
                font-size: 14px;
                font-family: "Inter", sans-serif;
                font-weight: 500;
                padding: 0 13px;
                outline: none;
                width: 100%;
            }
            .btn-flat{
                min-width: 99px;
                height: 45px;
                min-height: 45px;
            }
        }
        .content-notes{
            margin: -5px 0 0;
        }
        .invalid-refund{
            padding: 0.7rem 0.8rem;
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
    .accordion-readonly{
        opacity: .5;
        .item-label{
            --background: rgba(var(--ion-color-light-rgb), 0.8);
        }
    }

    .accordion-expanded{
        border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.3);
    }
}