.main-grid {
    min-height: 100%;
    height: -webkit-fill-available;
    width: 100%;

    ion-back-button{
        display: block;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        top: 10px;
        left: 10px;
        color: var(--ion-color-dark);
        &::part(text){
            display: none;
        }
    }
    ion-row {
    //   min-height: 100%;
      min-height: -webkit-fill-available;
    }
}

  
.box-wrapper{
    padding: 20px 50px;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 25px;
    box-shadow: 0 0 10px rgb(0 0 0 / 13%);
    ion-card-header{
        text-align: center;
        display: block;
        .restaurant-logo{
            // background-color: rgba(var(--ion-color-medium-rgb), 0.3);
            background: linear-gradient(45deg, #bdbdbd, #ebebeb);
            border-radius: 50%;
            width: 200px;
            height: 200px;
            margin: 0 auto 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img{
                max-width: 130px;
                margin-bottom: 0;
            }
        }
        img{
            width: 90%;
            height: auto;
            max-width: 250px;
            margin-bottom: 3rem;
        }
        ion-card-title {
            font-size: 26px;
            font-weight: 600;
            display: block;
            margin: 0 0 4px;
        }
        ion-card-subtitle{
            font-size: 20px;
            font-weight: 300;
            text-transform: none;
        }
        p{
            font-size: 1.5rem;
            max-width: 230px;
            text-align: center;
            margin: 0 auto 1rem;
            opacity: .6;
            line-height: 1.2;
            font-weight: 500;
        }
        small{
            font-size: 14px;
            font-weight: 400;
            color: var(--ion-color-dark);
        }
    }

    .button{
        width: 100%;
        margin: 0 auto 2rem;
        height: 45px;
        min-height: 45px;
        text-transform: none;
        &::part(native){
            box-shadow: none;
            border-radius: 10px;
            height: 100%;
        }
        &.btn{
            &::part(native){
                color: var(--ion-color-dark);
            }
        }
    }

    form{
        .item{
            margin-bottom: 2rem;
        }
    }

    .secondary-action{
        text-align: center;
        // padding: 20px 0 10px;
    }
}


.tab-segment{
    --min-height: unset;
    ion-segment {
        justify-content: flex-start;
        min-height: 46px;
        display: flex;
        ion-segment-button{
          font-family: $font-inter;
          font-size: 1.2rem;
          font-weight: 500;
          max-height: 46px;
          min-height: auto;
          flex: unset;
          ion-label{
            margin: 0;
            color: rgba(var(--ion-color-dark-rgb), 0.9);
            text-transform: none;
          }
        }
    }
}


.item-heading{
    --background: transparent;
    background: transparent;
    margin: 10px 0 0;
    h2{
      margin: 0;
      font-weight: 500;
    }
}

.file-upload-block{
    .imgUpload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 1.8;
        max-width: 250px;
        margin-bottom: 2rem;
        position: relative;
        height: 140px;
        &.outline {
          border: 1px dashed rgba(0, 0, 0, 0.26);
        }
        .innerBox {
          width: 100%;
          height: 100%;
        }
        .text {
          position: absolute;
          left: 50%;
          top: 50%;
          color: var(--ion-color-medium);
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 1;
          ion-icon {
            margin-right: 0.5rem;
            width: 30px;
            height: 30px;
          }
        }
        figure {
          border-radius: 1rem;
          height: 140px;
          text-align: center;
          margin: 0;
          img {
            border-radius: 1rem;
            height: 100%;
            width: auto;
            min-width: unset;
          }
        }
        &.active {
          border: none;
          .text {
            flex-direction: column;
            opacity: 0;
            transition: 0.3s ease all;
            color: var(--ion-color-light);
          }
          .remove {
            position: absolute;
            right: -5px;
            top: -5px;
            z-index: 1;
            background: var(--ion-color-danger);
            border-radius: 50%;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            ion-icon{
              color: #fff;
            }
          }
          figure {
            position: relative;
            overflow: hidden;
            &::before {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              opacity: 0;
              transition: 0.3s ease all;
              box-shadow: 0 0 82px rgb(0 0 0 / 70%) inset;
            }
          }
          &:hover {
            .text {
              opacity: 1;
            }
            figure {
              &::before {
                opacity: 1;
              }
            }
          }
        }
    }
}

.header-searchbar{
  border-radius: 10px;
  margin: 0 5px;
  width: calc(100% - 10px);
}

.title-short{
  font-size: 18px;
}

ion-breadcrumbs{
  padding: 0;
  margin-left: -3px;
  ion-breadcrumb{
    &::part(native){
      font-size: 12px;
      padding: 0 6px;
    }
    &::part(separator){
      font-size: 12px;
      margin: 0;
    }
  }
}

ion-header{
  .only-active{
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}

.selected-info{
  font-size: 13px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 8px 10px;
  margin: 0 16px;
  color: var(--ion-color-medium);
}

.table-scrollH{
  width: 100%;
  .table-header{
    th{
      min-height: unset;
      font-size: 13px;
      font-weight: 500;
      opacity: .7;
      padding-bottom: 10px;
      padding-left: 15px;
      &:first-child{
        text-align: left;
      }
    }

  }
  .table-body{
    td{
      border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.3);
      text-align: center;
      // min-height: 58px;
      height: 48px;
      padding-left: 15px;

      &:first-child {
        text-align: left;
      }
    }
    
  }
}

.custom-table {
  margin-bottom: 5px;
  ion-row {
    &.table-header{
      ion-col {
        min-height: unset;
        font-size: 13px;
        font-weight: 500;
        opacity: .7;
        padding-bottom: 10px;
      }

    }
    ion-col {
      border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.3);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 58px;

      &:first-child {
        text-align: left;
        justify-content: flex-start;
        padding-left: 15px;
      }
      &:last-child {
        text-align: right;
        justify-content: flex-end;
        padding-right: 15px;
      }
    }
    &:first-child,
    &:last-child {
      ion-col {
        border-bottom: 0;
      }
    }
  }
}


.numberInput{
  .native-input{
      &[type=number] {
          -moz-appearance: textfield;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button{
          -webkit-appearance: none;
          margin: 0;
      }
  }
}


.trsn-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  font-size: 17px;
  min-height: 55px;
  font-weight: 500;
  color: var(--ion-color-dark);
  border-top: 1px solid rgba(var(--ion-color-medium-rgb), 0.2);
  border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.2);
  background: rgba(var(--ion-color-medium-rgb), 0.1);
}


.paid-amt-list-container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  ion-card {
    // width: 140px;
    max-width: 250px;
    padding: 10px;
    margin: 10px;
    flex-grow: 1;
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.2);
    border-radius: $border-radius;
    text-align: center;
    display: inline-block;
    &.transaction-card{
      font-family: "Inter", sans-serif;
      text-align: left;
      .flex{
        justify-content: space-between;
        .left{
          .txt{
            opacity: 0.6;
            font-size: 1.2rem;
            padding-left: 6px;
            margin-bottom: 1px;
            font-weight: 500;
          }
          .total-amt{
            font-size: 2.2rem;
            display: flex;
            font-weight: 500;
            sup{
              font-size: 0.7em;
              opacity: 0.5;
              top: 8px;
              font-weight: 400;
            }
          }
          .paid-via{
            background: rgba(var(--ion-color-success-rgb), 0.1);
            color: var(--ion-color-success);
            font-size: 1.1rem;
            display: inline-flex;
            align-items: center;
            border-radius: 5px;
            padding: 0.4rem 0.5rem;
            margin: 6px 0 0 0px;
            line-height: 1.2;
            ion-icon{
              margin: 0 3px 0 -1px;
            }
          }
        }
        .right{
          .info-txt{
            margin: 0.8rem 0;
            font-size: 1.2rem;
            display: flex;
            flex-direction: column;
            font-weight: 400;
            color: rgba(var(--ion-color-dark-rgb), 0.8);
            span{
              font-size: .8em;
              position: relative;
              padding-left: 7px;
              &::before{
                content: "•";
                position: absolute;
                border-radius: 50%;
                left: -2px;
                top: -3px;
                font-size: 1.4em;
              }
            }
            &.primary{

            }
          }
        }
      }
      .card-info{
        display: flex;
        border-top: 1px solid rgba(var(--ion-color-medium-rgb), .3);
        margin-top: 10px;
        padding-top: 8px;
        justify-content: space-between;
        .info-col{
          font-size: 1.2rem;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          padding-left: 6px;
          color: rgba(var(--ion-color-dark-rgb), 0.8);
          span{
            font-size: .8em;
            position: relative;
            padding-left: 7px;
            margin-left: -6px;
            &::before{
              content: "•";
              position: absolute;
              border-radius: 50%;
              left: -2px;
              top: -3px;
              font-size: 1.4em;
            }
          }
          &.primary{

          }
        }
      }
    }
    strong {
      display: block;
      font-size: 1.8rem;
      color: var(--ion-color-dark);
    }
    ion-text {
      padding: 3px 5px;
      border-radius: $border-radius;
      span {
        font-size: 1.5rem;
        font-weight: 600;
        ion-icon {
          vertical-align: middle;
          font-weight: 900;
          font-size: 2rem;
        }
      }
    }
  }
}

@media(max-width:767px){
  .fullH-mob{
    height: 100%;
  }
  .box-wrapper{
      height: 100%;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-shadow: none;
      background: transparent;
  }
  ion-header{
    .only-active{
      font-size: 10px;
      // ion-label{
      //   display: none;
      // }
    }
  }
  
  .title-short{
    font-size: 16px;
  }

  .table-scrollH{
    .table-header{
      th{
        white-space: nowrap;
      }
    }
    .table-body{
      td{
        white-space: nowrap;
      }
    }
  }

  ion-breadcrumbs{
    ion-breadcrumb{
      .menu-name{
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
        white-space: nowrap;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }
  
  
}