// Generic modal style

ion-modal{
  &.rounded-modal {
    --height: auto;
    padding: 30px;
    &::part(content){
      border-radius: 16px;
      max-width: 500px;
    }
    &.big{
      &::part(content){
        width: auto;
        min-width: 800px;
        max-width: 860px;
      }
    }
    &.large{
      &::part(content){
        width: 1000px;
        max-width: 1000px;
      }
    }
    &.sml{
      &::part(content){
        max-width: 400px;
      }
    }
    &.custom-height-modal {
      .inner-content {
        height: 220px !important; /* Set the content height to 300px */
        max-height: 220px !important;
        overflow-y: auto; /* Add scrolling if content overflows */
      }
    }

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      ion-header{
        background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
        ion-toolbar{
          --background: transparent;
        }
      }

      .inner-content {
        background: var(--ion-color-light);
        max-height: 60vh;
        overflow: auto;
        padding: 16px 0;
        ion-item, 
        ion-list{
          background: transparent;
          --background: transparent;
        }
        app-no-data,
        ion-accordion-group{
          margin: 0 16px;
          display: block;
        }
      }

      ion-footer{
        background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
        // padding-top: 0;
        &::before{
          display: none;
        }
      }
    }
    &.custom-popover {
      // max-width: 90%;
      // --max-height: 70%;
      // margin: 0 auto;
    }
  }
}



/** Media Queries **/
@media(min-width:768px){
  ion-modal{
    &.rounded-modal {
      &.h452{
        .inner-content{
          height: 452px;
        }
      }
      &.mh452{
        .inner-content{
          min-height: 452px;
        }
      }
      &.mh377{
        .inner-content{
          min-height: 377px;
        }
      }
      &.custom-popover {
        max-width: 90%;
        margin: 0 auto;
        .inner-content{
          height: 70%;
        }
      }
    }
    
    
  }
}


@media (max-width: 767px) {
  // Generic modal style anup
  ion-modal{
    &.rounded-modal {
      padding: 0;
      &::part(content){
        border-radius: 0;
        --max-height: unset !important;
      }
      &.sml{
        &::part(content){
          max-width: unset;
        }
      }
      .ion-page {
        display: flex;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        .inner-content {
          // margin-bottom: auto;
          flex: 1;
          max-height: unset;
          overflow: auto;
        }
        ion-footer{
          // padding-top: 0;
          &::before{
            display: none;
          }
        }
      }
      &.custom-popover {
        max-width: 90%;
        margin: 0 auto;
        .ion-page {
          height: 70vh;
          .inner-content{
            // height: 70%;
          }
        }
      }
    }
  }

}