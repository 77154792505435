.desktop-wrapper {
  margin-left: 90px;
  // ion-toolbar {
  //   text-align: center;
  // }
  // ion-header {
  //   &.header-md:after {
  //     background: none;
  //   }
  // }
}
