.multi-child-table {
  width: 100%;

  .table-header {
    th {
      min-height: unset;
      font-size: 13px;
      white-space: nowrap;
      font-weight: 500;
      opacity: .7;
      padding-bottom: 10px;
      // padding-left: 15px;

      &:first-child {
        text-align: left;
      }
    }

  }

  .table-body {
    transition: .3s ease all;

    &:hover {
      background: rgba(var(--ion-color-medium-rgb), 0.1);
    }
  }

  tr {
    td {
      border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.3);
      &:first-child{
        padding-left: 26px !important;
      }
    }

    &.hasChild {
      td {
        padding: 0 1rem !important;

        &:first-child {
          position: relative;
          padding-left: 26px !important;
          overflow: visible;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            height: 9px;
            left: 6px;
            background-color: var(--ion-color-tertiary);
            opacity: 1;
            border-radius: 50%;
          }
        }
      }
    }

    &.nested {
      td {
        padding: 0 1rem !important;
        height: 40px;

        &:first-child {
          position: relative;
          padding-left: 42px !important;
          overflow: visible;

          &::before {
            content: "";
            position: absolute;
            top: -22px;
            width: 1px;
            height: calc(100% + 2px);
            right: auto;
            left: 9px;
            bottom: 0;
            border-left: 2px solid var(--ion-color-tertiary);
            opacity: 1;
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 25px;
            height: 2px;
            right: auto;
            left: 10px;
            border-top: 2px solid var(--ion-color-tertiary);
            opacity: 1;
          }
        }
      }
    }
  }
  th{ 
    padding: 12px 0;
    &:first-child {
        padding-left: 26px !important;
    }
  }
}


@media(max-width:767px){
  .multi-child-table{
    .table-header{
      th{
        padding-left: 16px;
      }
    }
    .table-body{
      td{
        white-space: nowrap;
        padding-left: 16px;
      }
    }
    tr{
      &.hasChild {
        td{
          padding-left: 16px !important;
          padding-right: 0 !important;
        }
      }
      &.nested td {
        padding: 0 0 0 16px !important;
      }
    }
  }
}