@import "/src/theme/globalVars";

:root {
  font-family: $font-family;
  font-size: 10px;
}
figure{
  margin: 0;
  padding: 0;
}
// Backgrounds
.bg-light {
  --background: var(--ion-color-light);
  background: var(--ion-color-light);
}

.relative{
  position: relative;
}

.nowrap{
  white-space: nowrap;
}

// Utilities
.glass-morp {
  backdrop-filter: blur(8px);
}
.dnone {
  display: none;
}
.item-no-left-padding {
  --padding-start: 0;
}
ion-backdrop.sc-ion-modal-md.md.backdrop-no-tappable {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
.toast {
  --max-width: 350px;
}

.vHidden {
  visibility: hidden;
}
.modal-close {
  ion-fab-button {
    --box-shadow: none;
    &::part(native) {
      background: transparent;
    }
  }
}

.inactive{
  pointer-events: none;
  opacity: .5;
}

.sc-ion-modal-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.icon-list{
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem;
  font-size: 1.3rem;
  li{
    padding-left: 23px;
    position: relative;
    margin-bottom: 9px;
    ion-icon{
      position: absolute;
      left: 0;
      font-size: 1.8rem;
    }
  }
}

.transparent-input {
  --background: rgb(255 255 255 / 10%);
  --border-color-focused: red;
  --border-color: rgb(255 255 255 / 45%);
  margin-bottom: 10px;
  &.item-has-focus {
    outline: 1px solid rgb(255 255 255 / 45%);
    padding: 0;
  }
  ion-label {
    color: white !important;
    opacity: 0.8;
  }
  ion-input,
  ion-select {
    --placeholder-color: white;
    --color: white;
    color: white;
  }
  ion-icon {
    color: white;
  }
}

.white-button {
  --background: #fff;
  --color: var(--ion-color-primary);
  --box-shadow: 0 0 6px rgb(0 0 0 / 15%);
}
.no-shadow {
  box-shadow: none;
  --box-shadow: none;
}
.pointer {
  cursor: pointer;
}
.pointer-events-none {
  pointer-events: none;
}
.radius,
.rounded {
  border-radius: 5px;
}
.align-center {
  text-align: center;
}

.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 8px;
}

.edit-note-model {
  --width: 315px;
  --height: 200px;
}

.scrlItem {
  opacity: 0;
  transform: scale(0.5);
  transition: 0.5s ease all;
}

.scrlItem.active-on-scroll {
  opacity: 1;
  transform: scale(1);
}

.spin {
  animation: spin-animation 1.5s linear  infinite ;
}

.alert-btns{
  button.alert-button:nth-child(1) .alert-button-inner{
    color: var(--ion-color-danger);
  }
  
  // button.alert-button:nth-child(2) .alert-button-inner{
  //   color: var(--ion-color-success);
  // }
}

.nowrap{
  white-space: nowrap;
}

.border-rounded{
  border-radius: 50%;
}


@keyframes spin-animation {
  from {
    transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

@keyframes blink {
  0% {
      opacity: 1;
  }

  50% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}


// ** Status **//
.app-success-status {
  background: rgba(var(--ion-color-success-rgb), 0.1);
  ion-note {
    color: rgba(var(--ion-color-success-rgb), 0.7);
  }
}
.app-warning-status {
  background: rgba(var(--ion-color-warning-rgb), 0.1);
  ion-note {
    color: rgba(var(--ion-color-warning-rgb), 0.7);
  }
}

.info-note {
  background: rgba(var(--ion-color-secondary-rgb), 0.1);
  font-size: 1.3rem;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  align-items: center;
  .icon {
    margin-right: 10px;
    background: var(--ion-color-secondary);
    color: var(--ion-color-light);
    padding: 4px;
    align-self: stretch;
    align-items: center;
    display: flex;
    ion-icon {
      font-size: 2.2rem;
      vertical-align: middle;
      color: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
    }
  }
  .txt{
    color: var(--ion-color-secondary);
    margin: 0.2rem 0;
  }

  &.tertiary{
    background: rgba(var(--ion-color-tertiary-rgb), 0.1);
    .icon {
      background: var(--ion-color-tertiary);
    }
    .txt{
      color: var(--ion-color-tertiary);
    }
  }

  &.medium{
    background: rgba(var(--ion-color-medium-rgb), 0.1);
    .icon {
      background: var(--ion-color-medium);
    }
    .txt{
      color: var(--ion-color-medium);
    }
  }
}

.note-warn {
  background: rgba(var(--ion-color-warning-rgb), 0.1);
  font-size: 1.3rem;
  display: flex;
  // overflow: hidden;
  border-radius: 5px;
  align-items: center;
  text-align: left;
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  ion-icon {
    font-size: 2rem;
    min-width: 2rem;
    vertical-align: middle;
    margin-right: 0.8rem;
    color: var(--ion-color-warning);
  }
  .txt{

  }
}
.note-tertiary {
  background: rgba(var(--ion-color-tertiary-rgb), 0.1);
  font-size: 1.3rem;
  display: flex;
  // overflow: hidden;
  border-radius: 5px;
  align-items: center;
  text-align: left;
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  ion-icon {
    font-size: 2rem;
    min-width: 2rem;
    vertical-align: middle;
    margin-right: 0.8rem;
    color: var(--ion-color-warning);
  }
  .txt{

  }
}
.note-danger {
  background: rgba(var(--ion-color-danger-rgb), 0.1);
  font-size: 1.3rem;
  display: flex;
  // overflow: hidden;
  border-radius: 5px;
  align-items: center;
  text-align: left;
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  ion-icon {
    font-size: 2rem;
    min-width: 2rem;
    vertical-align: middle;
    margin-right: 0.8rem;
    color: var(--ion-color-danger);
  }
  .txt{

  }
}
.note-success {
  background: rgba(var(--ion-color-success-rgb), 0.1);
  font-size: 1.3rem;
  display: flex;
  // overflow: hidden;
  border-radius: 5px;
  align-items: center;
  text-align: left;
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  ion-icon {
    font-size: 2rem;
    min-width: 2rem;
    vertical-align: middle;
    margin-right: 0.8rem;
    color: var(--ion-color-success);
  }
  .txt{

  }
}

.app-status {
  &.accepted {
    background: rgba(var(--ion-color-success-rgb), 0.1);
    ion-note {
      color: rgba(var(--ion-color-success-rgb), 0.7);
    }
  }
  &.pending {
    background: rgba(var(--ion-color-warning-rgb), 0.1);
    ion-note {
      color: rgba(var(--ion-color-warning-rgb), 0.7);
    }
  }
}

.app-button {
  --border-radius: 8px !important;
  min-width: 120px;
  text-transform: capitalize;
}

.btn {
  --border-radius: 8px !important;
  text-transform: capitalize;
  &::part(native){
    padding: 1rem 1.5rem;
  }
}

.loading-btn{
  .txt{
    display: flex;
    align-items: center;
    color: inherit;
  }
  ion-spinner{
    position: absolute;
    top: calc(50% - 14px);
    left: calc(50% - 14px);
  }
}

.app-fg-primary {
  --color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary) !important;
}
.app-bg-primary-5 {
  --background: rgba(var(--ion-color-primary-rgb), 0.05) !important;
  background: rgba(var(--ion-color-primary-rgb), 0.05) !important;
}

.app-fg-medium {
  --color: var(--ion-color-medium) !important;
  color: var(--ion-color-medium) !important;
}
.app-bg-medium-10 {
  --background: rgba(var(--ion-color-medium-rgb), 0.1) !important;
  background: rgba(var(--ion-color-medium-rgb), 0.1) !important;
}

.app-fg-dark {
  --color: var(--ion-color-dark) !important;
  color: var(--ion-color-dark) !important;
}
.app-fg-dark-80 {
  --color: rgba(var(--ion-color-dark-rgb), 0.8);
  color: rgba(var(--ion-color-dark-rgb), 0.8);
}

.app-bg-dark-10 {
  --background: rgba(var(--ion-color-dark-rgb), 0.1) !important;
  background: rgba(var(--ion-color-dark-rgb), 0.1) !important;
}

.app-fg-success {
  --color: var(--ion-color-success) !important;
  color: var(--ion-color-success) !important;
}
.app-bg-success-5 {
  --background: rgba(var(--ion-color-success-rgb), 0.05) !important;
  background: rgba(var(--ion-color-success-rgb), 0.05) !important;
}
.app-bg-success-10 {
  --background: rgba(var(--ion-color-success-rgb), 0.1) !important;
  background: rgba(var(--ion-color-success-rgb), 0.1) !important;
}

// ** Global Page Level Styles **/

//** Popover Menu Options Starts  **//
.app-menu-options .popover-content {
  max-width: 200px;
  padding: 0 10px;
}
//** Popover Menu Options Ends **//

//** Order Details Starts **//


.info-detail-popup {
  --border-radius: $border-radius;
  --width: 400px;
  --max-height: 480px;
  --margin: 0px 10px;
  --backdrop-opacity: 0.5 !important;
}

//** Order Details Ends **//

//** Cart View Starts **//
.cart-view .modal-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  --width: 100% !important;
  --height: 100% !important;
}

.cart-view {
  --width: 100% !important;
  --height: 100% !important;
}

.bottom-cart-view-container {
  font-family: $font-family;
}
//** Cart View Ends **//



//** Transactions Starts **//
.custom-card {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  ion-card-header {
    padding-bottom: 0;
    ion-icon {
      font-size: 24px;
    }
    .top-toggle {
      position: absolute;
      top: 15px;
      right: 15px;
      transform: scale(0.9);
    }
  }
  .card-header {
    ion-label {
      flex: 1;
    }
    display: flex;
    .ripple-parent {
      position: relative;
      overflow: hidden;
    }
    .action-menu-btn {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-top: -9px;
      cursor: pointer;
    }
  }
  ion-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .user-profile-thumbnail {
      margin-top: 10px;
      width: 100%;
      height: 140px;
      img {
        border-radius: $border-radius;
        object-position: top;
      }
    }
    .customer-name-text {
      font-size: 2.1rem;
      font-weight: 500;
      color: var(--ion-color-dark);
      margin-bottom: 5px;
    }
    .amount {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 5px;
      color: var(--ion-color-medium);
    }
    .order-status {
      padding: 2px 10px;
      margin-bottom: 10px;
      &.accepted {
        background: rgba(var(--ion-color-success-rgb), 0.1);
        ion-note {
          color: rgba(var(--ion-color-success-rgb), 0.9);
        }
      }
      &.pending {
        background: rgba(var(--ion-color-warning-rgb), 0.1);
        ion-note {
          color: rgba(var(--ion-color-warning-rgb), 0.9);
        }
      }
    }
    .date-time-text {
      font-size: 1.2rem;
      color: var(--ion-color-medium);
      ion-icon {
        vertical-align: middle;
      }
    }
  }
  &.align-flex-start {
    ion-card-content {
      align-items: flex-start;
    }
  }
}
//** Transactions Starts **//

//** Split Amount/Item Starts **//
.bottom-amount-section {
  border-top: 1px solid rgba(var(--ion-color-medium-rgb), 0.5);
  width: 100%;
  padding-top: 10px;
  span {
    float: right;
  }
  .order-amt {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0 0 10px 0;
  }
  .order-total {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
}
//** Split Amount/Item Ends **//

.pins {
  padding: 5px 0;
  &.desktop {
    column-count: 4;
  }
  &.ipad {
    column-count: 3;
  }
  &.mobile {
    column-count: 1;
  }
}

.pin {
  width: calc(100% - 20px);
  display: inline-block;
  // margin: 0 10px 20px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.back-btn {
  margin-right: -16px !important;
}

.minH0{
  min-height: 0 !important;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
//** Transaction Ends **//

/** Media Queries **/
@media (max-width: 767px) {
  .ios .info-detail-popup {
    --width: 100%;
  }
}

@media screen and (max-width: 470px) {
  .ios .info-detail-popup {
    --width: 100%;
  }
}
