/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/includes";

.home-card-modal .modal-wrapper {
  height: 100vh;
  width: 300px;
  position: absolute;
  right: 0;
}

ion-item-divider {
  border: none !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.primary {
  color: var(--ion-color-primary);
}

.cart-dropdown .select-interface-option {
  font-size: 14px !important;
  --padding-start: 10px !important;
  --inner-padding-end: 0px !important;
  --min-height: 37px !important;
}
.cart-table-dropdown .select-interface-option {
  font-size: 14px !important;
  --padding-start: 10px !important;
  --inner-padding-end: 0px !important;
  --min-height: 37px !important;
}

.cart-dropdown::part(content) {
  --width: 145px !important;
}

.bottom-link{
  text-align: right;
  font-size: 12px;
  padding-top: .7rem;
}

.btn-custom{
  background-color: var(--ion-color-light);
  // --background: rgba(var(--ion-color-medium-rgb), 0.3);
  text-transform: none;
  height: unset !important;
  border-radius: 4px;
  &::part(native){
    padding: 8px 10px;
  }
  .flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    small{
      font-weight: 500;
      letter-spacing: 2px;
      opacity: .7;
      margin-bottom: 3px;
    }
  }
  .txt{
    display: block;
  }
}


.btn-flat{
  // width: 100%;
  margin: 0 auto;
  height: 35px;
  min-height: 35px;
  text-transform: none;
  &::part(native){
    box-shadow: none;
    border-radius: 10px;
    height: 100%;
    color: var(--ion-color-dark);
  }
  &.color{
    &::part(native){
      color: unset;
    }

  }
}

#notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 200px;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.hidden {
    display: none;
}

#close-button {
    min-height: 3rem;
    outline: none;
    cursor: pointer;
    font-family: "Cera Pro";
    border-radius: 12px;
    border: none;
}
#restart-button {
    min-height: 3rem;
    outline: none;
    cursor: pointer;
    font-family: "Cera Pro";
    border-radius: 12px;
    border: none;
    background: green;
    color: white;
}
