.custom-scrollbar {
  overflow: auto;
  // padding-bottom: 8px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    left: -10px;
    position: relative;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: rgba(var(--ion-background-color-rgb), 0.66);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0;
  }
  
  &:hover{
    &::-webkit-scrollbar-thumb {
      background: rgba(var(--ion-color-dark-rgb), 0.2);
    }
    &::-webkit-scrollbar-track {
      background: rgba(var(--ion-color-dark-rgb), 0.1);
    }
  }

}

ion-content.custom-scrollbar{
  --offset-bottom: auto !important;
  --overflow: auto;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    left: -10px;
    position: relative;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: rgba(var(--ion-background-color-rgb), 0.66);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(var(--ion-color-dark-rgb), 0.2);
    }

    &::-webkit-scrollbar-track {
      background: rgba(var(--ion-color-dark-rgb), 0.1);
    }
  }

  .inner-scroll {
    scrollbar-width: thin;
  }
}

@media(max-width:767px){
  .custom-scrollbar {
    -webkit-overflow-scrolling: touch;
  }
  .custom-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb,
  .custom-scrollbar::-webkit-scrollbar-track {
    display: none;
  }
  .custom-scrollbar::-webkit-scrollbar {
    position: static;
  }

  ion-content.custom-scrollbar{
    -webkit-overflow-scrolling: touch;
  
    &::-webkit-scrollbar {
      display: none;
    }
  
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  
    &::-webkit-scrollbar-track {
      display: none;
    }

    &::part(scroll){

      &::before {
        bottom: 0;
      }
    }
  }
}